<template>
  <b-container class="grey-bg text-center mb-3 mt-3" @click="$router.push('/tests')">
    <b-row>
      <b-col>
        <h3>Ihr Letzter Test</h3>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col lg="10" class="card green-bg text-white">
        <h4>Negativ</h4>
        <div>21.04.2021 12:40</div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "last_test"
}
</script>

<style scoped>
.card {
  border-radius: 8px;
}
</style>
