<template>
  <div class="view">
    <div class="content">
      <logo-banner/>
      <risk/>
      <checkin/>
      <lastTest/>
      <cta/>
    </div>

    <menu-bar active-button="index"></menu-bar>
  </div>
</template>

<script>
import menuBar from "../components/MenuBar";
import checkin from "../components/Checkin";
import lastTest from "../components/LastTest";
import cta from "../components/Cta";
import logoBanner from "../components/LogoBanner";
import Risk from "../components/Risk";

export default {
  name: "Index",
  components: {
    Risk,
    logoBanner,
    cta,
    lastTest,
    checkin,
    menuBar,
  }
}
</script>
