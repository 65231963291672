<template>
  <img class="banner" src="~@/assets/LogoWithText.png" alt="logo">
</template>

<script>
export default {
  name: "LogoBanner"
}
</script>

<style scoped>
.banner {
  width: 100%;
  height: 114px;
}
</style>