<template>
  <vue-fab mainBtnColor="#21BD64" size="big" class="cta">
    <fab-item @clickItem="$router.push('tests/new')" :idx="0" title="Test hinzufügen" icon="add" />
    <fab-item @clickItem="$router.push('qr/new')" :idx="1" title="QR Code erstellen" icon="qr_code" />
  </vue-fab>
</template>

<script>
export default {
  name: "cta"
}
</script>

<style scoped>
.fab-main-container {
  bottom: 20% !important;
}

.fab-cantainer:first-of-type {
  top: -50px !important;
}

.fab-cantainer:last-of-type {
  top: -100px !important;
}
</style>
