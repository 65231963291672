<template>
  <b-container :class="(risk ? 'red-bg ' : 'green-bg ') + 'text-white text-center mb-3 mt-3'" @click="toggleRisk">
    <h2>
      <template v-if="risk">Hohes Risiko</template>
      <template v-else>Niedriges Risiko</template>
    </h2>

    <template v-if="risk">
      <div class="ml-1"><b>2</b> Risikobegegnungen<br>
        <b>6</b> Tage seit der letzten Begegnung<br>
      </div>
    </template>

    <div>Aktualisiert: <b>heute, 14:01</b></div>

  </b-container>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Risk",
  computed: {
    ...mapGetters([
        'getRisk'
    ]),
    risk () {
      return this.getRisk
    }
  },
  methods: {
    ...mapMutations([
        'toggleRisk'
    ]),
  }
}
</script>

