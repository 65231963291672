<template>
  <div class="menuBar">
    <router-link :to="{name: 'Index'}"
                 :class="activeButton === 'index' ? 'menuBarButton active': 'menuBarButton'">
      <svg class="menuBarIcon index"></svg>
      Index
    </router-link>
    <router-link :to="{name: 'Tests'}"
                 :class="activeButton === 'tests' ? 'menuBarButton active': 'menuBarButton'">
      <svg class="menuBarIcon tests"></svg>

      Tests
    </router-link>
    <router-link :to="{name: 'CheckIns'}"
                 :class="activeButton === 'checkins' ? 'menuBarButton active': 'menuBarButton'">
      <svg class="menuBarIcon checkins"></svg>

      Check Ins
    </router-link>
    <router-link :to="{name: 'Settings'}"
                 :class="activeButton === 'settings' ? 'menuBarButton active': 'menuBarButton'">
      <svg class="menuBarIcon settings"></svg>

      Settings
    </router-link>
  </div>
</template>

<script>
export default {
  name: "MenuBar",
  props: {
    activeButton: {
      type: String,
      default: 'index',
    }
  }
}
</script>

<style scoped>
.menuBar {
  border-top: 1px solid #C8C8C8;
  background: white;
  height: 72px;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: grid;
  grid-template-columns: [index]25% [tests]25% [checkins]25% [settings]25%;
}

.menuBar > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}

.menuBarIcon {
  margin-top: 8px;
  background: red;
  width: 40px;
  height: 40px;
}

.menuBar > a.active {
  filter: invert(54%) sepia(66%) saturate(507%) hue-rotate(93deg) brightness(96%) contrast(92%);
}

.menuBarIcon.index {
  background: center center no-repeat url("~@/assets/icons/home.svg");
  background-size: cover;
}

.menuBarIcon.tests {
  background: center center no-repeat url("~@/assets/icons/swab.svg");
  background-size: cover;
  width: 35px;
  height: 35px;
}

.menuBarIcon.checkins {
  background: center center no-repeat url("~@/assets/icons/location.svg");
  background-size: cover;
}

.menuBarIcon.settings {
  background: center center no-repeat url("~@/assets/icons/settings.svg");
  background-size: cover;
}

</style>