<template>
  <b-container class="bg-grey mb-3 mt-3">
    <div v-if="checkedIn" style="display: flex; justify-content: center; align-items: center; flex-direction: column">
      <radial-progress-bar
          v-if="showBar"
          :diameter="250"
          :completed-steps="minutesPassed"
          :total-steps="getLatestCheckIn.defaultStayTime"
          :startColor="'#2F80ED'"
          :stopColor="'#2F80ED'"
          :strokeWidth="20"
          :innerStrokeWidth="20"
          :innerStrokeColor="'#AFCAE5'">
        <h3>{{ getLatestCheckIn.name }}</h3>
        <span class="text-black-50">Verbrachte Zeit:</span>
        <h3>{{ msToTime(timeBetweenStartAndNow) }}</h3>
        <span >Mittlere Verweildauer:</span>
        <span>{{ msToTime(getLatestCheckIn.defaultStayTime * 60 * 1000, true) }}</span>
      </radial-progress-bar>

      <b-button variant="danger" block @click="handleCheckOut">Auschecken</b-button>
    </div>
    <div v-else>
      <b-row align-h="center" align-v="center">
        <svg class="scanner"/>
        <h3 style="margin: 0">Einchecken</h3>
      </b-row>
      <b-row align-h="center" style="margin-top: 8px">
        <b-button class="grey-btn" @click="$router.push({name: 'Scan', params: {type:'checkIn'}})">Scannen</b-button>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import RadialProgressBar from 'vue-radial-progress'


export default {
  name: "Checkin",
  components: {
    RadialProgressBar
  },
  data () {
    return {
      showBar: true,
      now: 0,
      refreshInterval: null,
    }
  },
  mounted() {
    this.updateNow()
    setInterval(this.updateNow.bind(this) , 1000)
  },
  computed: {
    ...mapGetters([
      'getCheckedIn',
      'getLatestCheckIn'
    ]),
    checkedIn() {
      return this.getCheckedIn
    },
    minutesPassed () {
      let minutes = this.minutesBetweenTwoDates(this.now, ((this.getLatestCheckIn || {}).date || {}))
      return minutes > 0 ? minutes : 0
    },
    timeBetweenStartAndNow () {
      return this.now - ((this.getLatestCheckIn || {}).date || {})
    }
  },
  methods: {
    ...mapMutations([
      'checkOut'
    ]),
    minutesBetweenTwoDates(first, last) {
      return Math.floor(((first - last) / 1000) / 60)
    },
    msToTime(duration, noSeconds) {
      let seconds = Math.floor((duration / 1000) % 60),
          minutes = Math.floor((duration / (1000 * 60)) % 60),
          hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hours = (hours < 10) ? "0" + hours : hours;
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      seconds = (seconds < 10) ? "0" + seconds : seconds;

      return hours + ":" + minutes + (noSeconds ? '' : (":" + seconds));
    },
    updateNow() {
      this.now = Math.round(Date.now())
    },
    handleCheckOut () {
      clearInterval(this.refreshInterval)
      this.checkOut()
    }
  }
}
</script>

<style scoped>
.bg-grey {
  background-color: #E3E3E3;
}

.grey-btn {
  background-color: #C4C4C4;
  outline: none;
  border: none;
  color: black;
}

.scanner {
  background: center center no-repeat url("~@/assets/icons/scanner.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
}
</style>
